import { AccomodationEntity } from '../../../shared/src/types/AccomodationEntity';
import { FlightEntity } from '../../../shared/src/types/FlightEntity';
import { LegEntity } from '../../../shared/src/types/LegEntity';
import { CarRentalEntity } from '../../../shared/src/types/RentalCarEntity';
import { TripDayEntity } from '../../../shared/src/types/TripDayEntity';
import { TripEntity } from '../../../shared/src/types/TripEntity';
import { WaypointEntity } from '../../../shared/src/types/WaypointEntity';
import { EntityState } from '../../types/normalize';

export interface TripLeg {
  start_address: string;
  end_address: string;
  dest_waypoint_id: string;
}

export type RoadbookState = {
  user: {
    email: string;
    picture: string;
  };
  app: {
    editMode: boolean;
    showTransit: boolean;
    selectedDayIndex: number[];
    darkMode: boolean;
  };
  trips: EntityState<TripEntity>;
  trip_days: EntityState<TripDayEntity>;
  waypoints: EntityState<WaypointEntity>;
  accomodations: EntityState<AccomodationEntity>;
  rentalCars: EntityState<CarRentalEntity>;
  flights: EntityState<FlightEntity>;
  legs: EntityState<LegEntity>;
};

export const initialState: RoadbookState = {
  app: {
    editMode: true,
    showTransit: false,
    selectedDayIndex: [],
    darkMode: false,
  },
  user: {
    email: '',
    picture: '',
  },
  trips: {
    loaded: false,
    allIDs: [],
    byID: {},
    idsBy: {
      tripDayID: {},
    },
  },

  trip_days: {
    loaded: false,
    allIDs: [],
    byID: {},
    idsBy: {
      waypointID: {},
      tripID: {},
    },
  },

  waypoints: {
    loaded: false,
    allIDs: [],
    byID: {},
    idsBy: {
      dayID: {},
      legID: {},
      accomodationID: {},
      rentalCarID: {},
      flightID: {},
    },
  },

  accomodations: {
    loaded: false,
    allIDs: [],
    byID: {},
    idsBy: {
      waypointID: {},
    },
  },

  rentalCars: {
    loaded: false,
    allIDs: [],
    byID: {},
    idsBy: {
      waypointID: {},
    },
  },

  flights: {
    loaded: false,
    allIDs: [],
    byID: {},
    idsBy: {
      waypointID: {},
    },
  },

  legs: {
    loaded: false,
    allIDs: [],
    byID: {},
    idsBy: {
      waypointID: {},
    },
  },
};
