import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { userAgent } from '@googlemaps/google-maps-services-js';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import Container from '@mui/material/Container';
import { format } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import { cp } from 'fs';
import React from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { useUserContext } from 'supertokens-auth-react';
import {
  signOut,
  useSessionContext,
} from 'supertokens-auth-react/recipe/session';

import { actionRetriveUserInfo } from '../actions/users';
import { useRoadbookContext } from '../context/RoadbookContext';
import Roadbook from './Roadbook';

const pages = ['Products', 'Pricing', 'Blog'];

export default function Home() {
  const { state, dispatchAction } = useRoadbookContext();
  const navigate = useNavigate();
  const session = useSessionContext();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  React.useEffect(() => {
    if (session.loading === false && session.doesSessionExist) {
      dispatchAction(actionRetriveUserInfo());
    }
  }, [session]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleLogout = async () => {
    await signOut();
    navigate('/auth');
    setAnchorElNav(null);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const trips = state.trips.allIDs.map((id) => state.trips.byID[id]);

  const { user } = state;

  if (session.loading) {
    return <div>Loading...</div>;
  }

  if (session.doesSessionExist === false) {
    return <div>Session does not exist</div>;
  }

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <AppBar position="static" sx={{ borderRadius: 0 }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <FontAwesomeIcon
              icon="road"
              style={{ marginRight: '16px' }}
              size="2x"
            />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontWeight: 700,
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              Roadbook
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={user.email} src={user.picture} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Box
        sx={{
          m: 'auto',
          p: 2,
          top: '50px',
          flexGrow: 1,
          width: '100%',
        }}
      >
        <ul>
          {trips.map((trip) => {
            const formattedDate = format(trip.start_date, 'MMMM do, yyyy', {
              locale: fr,
            });
            return (
              <li key={trip.id}>
                <a href={`/t/${trip.id}`}>
                  {trip.name} - {formattedDate}
                </a>
              </li>
            );
          })}
        </ul>
      </Box>
    </Box>
  );
}
