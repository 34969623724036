import { Action } from '../../../shared/src/types/Action';
import { AsyncAction } from '../types/AsyncAction';

export function actionRetriveUserInfo(): AsyncAction {
  return async (dispatch: React.Dispatch<Action>, getState) => {
    const response = await fetch('/api/v2/user');
    const userInfo = await response.json();
    dispatch({ type: 'RETRIEVE_USER_INFO', payload: userInfo });
  };
}
