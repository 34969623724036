import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';
import EmailVerification from 'supertokens-auth-react/recipe/emailverification';
import { EmailVerificationPreBuiltUI } from 'supertokens-auth-react/recipe/emailverification/prebuiltui';
import Session from 'supertokens-auth-react/recipe/session';
import ThirdParty, { Google } from 'supertokens-auth-react/recipe/thirdparty';
import { ThirdPartyPreBuiltUI } from 'supertokens-auth-react/recipe/thirdparty/prebuiltui';

export const SuperTokensConfig = () => {
  return {
    appInfo: {
      appName: 'Roadbook',
      apiDomain: window.location.origin,
      websiteDomain: window.location.origin,
      websiteBasePath: '/auth',
      apiBasePath: '/api',
    },
    // recipeList contains all the modules that you want to
    // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
    recipeList: [
      ThirdParty.init({
        signInAndUpFeature: {
          providers: [Google.init()],
        },
        onHandleEvent: (context) => {},
      }),
      Session.init(),
    ],
    useShadowDom: false,
  };
};

export const PreBuiltUIList = [ThirdPartyPreBuiltUI];
