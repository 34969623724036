import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import React from 'react';



import { useRoadbookContext } from '../../context/RoadbookContext';

const formatDateRange = (startDate: Date, endDate: Date) => {
  const startDay = format(startDate, 'd', { locale: fr });
  const startMonthYear = format(startDate, 'MMMM yyyy', { locale: fr });
  const endDay = format(endDate, 'd', { locale: fr });
  const endMonthYear = format(endDate, 'MMMM yyyy', { locale: fr });

  if (startMonthYear === endMonthYear) {
    return `Du ${startDay} au ${endDay} ${startMonthYear}`;
  } else {
    return `Du ${startDay} ${startMonthYear} au ${endDay} ${endMonthYear}`;
  }
};

export default function Header({ tripID }: { tripID: string }) {
  const { state, dispatch } = useRoadbookContext();

  const trip = state.trips.byID[tripID];

  if (!trip) {
    return <div>Chargement...</div>;
  }

  const days = state.trip_days.idsBy.tripID[tripID];

  if (!days) {
    return <div>Chargement...</div>;
  }

  const endDate = new Date(trip.start_date);
  endDate.setDate(endDate.getDate() + days.length - 1);
  const dateRange = formatDateRange(trip.start_date, endDate);

  const dates = days.map((dayID) => {
    const d = new Date(trip.start_date);
    d.setDate(d.getDate() + days.indexOf(dayID));
    return {
      date: format(d, 'd', { locale: fr }),
      letter: format(d, 'EEEE', { locale: fr }).substring(0, 3).toUpperCase(),
    };
  });

  // Filer to start the line on Mondays
  const fillerDate = new Date(trip.start_date);
  fillerDate.setDate(fillerDate.getDate() - 1);
  const nfill = fillerDate.getDay();
  const filler = Array(nfill).fill(0);
  const endFill = 7 - ((days.length + nfill) % 7);
  const endFiller = Array(endFill).fill(0);

  const backgroundStyle = {
    padding: '20px',
    height: '340px',
    color: 'white',
    backgroundImage: `url(${process.env.PUBLIC_URL + '/images/isl1.jpg'})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundStyle: 'no-repeat',
    backgroundPositionX: '50%',
    backgroundPositionY: '50%',
    display: 'flex',
  };

  const handleSelectDay = (e: React.MouseEvent<HTMLDivElement>) => {
    const index = parseInt(e.currentTarget.id);

    // Get shift key as modifier
    if (e.shiftKey) {
      const selected = state.app.selectedDayIndex;
      const lastSelected = selected[selected.length - 1];
      const range =
        lastSelected < index ? [lastSelected, index] : [index, lastSelected];
      const newSelected = Array.from(
        { length: range[1] - range[0] + 1 },
        (_, i) => i + range[0],
      );
      dispatch({
        type: 'SET_SELECTED_DAYS',
        payload: { selectedDays: newSelected },
      });
      return;
    }

    dispatch({
      type: 'SET_SELECTED_DAYS',
      payload: {
        selectedDays:
          state.app.selectedDayIndex.includes(index) &&
          state.app.selectedDayIndex.length === 1
            ? []
            : [index],
      },
    });
  };

  const isSelected = (index: number) => {
    return state.app.selectedDayIndex.includes(index)
      ? 'day-selector-active'
      : '';
  };

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleToggleMode = () => {
    dispatch({ type: 'TOGGLE_DARK_MODE' });
    handleMenuClose();
  };

  return (
    <header style={backgroundStyle}>
      <div className="header">
        <Box className="header__settings">
          <IconButton ref={anchorRef} onClick={handleMenuOpen}>
            <FontAwesomeIcon icon="gear" style={{ color: 'white' }} size="xs" />
          </IconButton>
          <Menu
            anchorEl={anchorRef.current}
            open={isMenuOpen}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleToggleMode}>
              <ListItemIcon>
                <FontAwesomeIcon icon="moon" />
              </ListItemIcon>
              <ListItemText>Dark Mode</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                console.log(state);
                handleMenuClose();
              }}
            >
              <ListItemIcon>
                <FontAwesomeIcon icon="terminal" />
              </ListItemIcon>
              <ListItemText>Log State</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
        <h1>{trip.name}</h1>
        <p style={{ flex: 1 }}>{dateRange}</p>

        <div className="day-selector-container">
          {filler.map((_, index) => (
            <Box
              key={index}
              sx={{
                width: 37,
                height: 37,
                backgroundColor: 'rgba(255,255,255,0.2)',
                borderRadius: '5px',
              }}
            ></Box>
          ))}
          {dates.map((day, index) => (
            <div
              key={index}
              className={'day-selector ' + isSelected(index)}
              id={index.toString()}
              onClick={handleSelectDay}
            >
              <div className="day-selector-date">{day.date}</div>
              <div className="day-selector-day">{day.letter}</div>
            </div>
          ))}
          {endFiller.map((_, index) => (
            <Box
              key={index}
              sx={{
                width: 37,
                height: 37,
                backgroundColor: 'rgba(255,255,255,0.2)',
                borderRadius: '5px',
              }}
            ></Box>
          ))}
        </div>
      </div>
    </header>
  );
}